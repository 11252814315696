<template>
  <div class="col-12 col-lg-6 c">
    <div class="card">
      <div class="card-header">
        <h4>
          <i class="fa fa-plus"></i>
          إضافة خدمة جديدة
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="">العنوان</label>
          <input type="text" class="form-control" v-model="service.title" />
        </div>
        <div class="form-group">
          <label for="">الكود (مثل: tahdir, naqar, brmja)</label>
          <input type="text" class="form-control" v-model="service.code" />
        </div>
        <div class="form-group">
          <label for="">الصورة</label>
          <input type="text" class="form-control" v-model="service.image" />
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-success" @click="addNow()">
            إضافة الخدمة <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      service: {},
    };
  },
  methods: {
    addNow() {
      var g = this;
      $.post(api + "/console/services/add", {
        jwt: this.user.jwt,
        service: this.service,
      }).then(function (r) {
        g.$router.push("/services");
      });
    },
  },
  created() {
    var g = this;
  },
};
</script>